<template>
  <v-container container--fluid>
    <v-row justify="center" class="mb-12">
      <v-col cols="10" class="mt-12">
        <h2>비밀번호 변경</h2>
      </v-col>
      <v-col cols="10" class="line mt-4 pb-4">
        <p>
          <span class="font-weight-bold">비밀번호를 변경할 수 있는 메일을 보내드립니다.</span><br>
          이메일을 잊으신 경우 고객센터에 문의해주세요.
        </p>
        <!--<v-divider class="mt-12"></v-divider>-->
      </v-col>
      <v-col cols="10" md="6" offset-md="3" class="mt-12 mb-12">
        <form @submit.prevent="onSubmit" id="signUpForm">
          <v-col v-if="step == 0">
            <v-col class="mb-1" v-if="!userIsAuthenticated" >
              <v-col>
                <v-col>
                  <div class="label font-weight-bold">가입 이메일</div>
                </v-col>
                <v-col class="my-1" >
                  <v-text-field v-model="form.email" name="email" type="text"
                                v-validate="'required|min:3'"
                                :rules="[errors.first('email') || !errors.has('email')]"
                                hide-details
                                single-line outlined solo flat required></v-text-field>
                </v-col>
              </v-col>
            </v-col>
            <v-col class="text-center my-1" >
              <v-btn depressed color="primary" type="submit">비밀번호 변경 메일전송</v-btn>
            </v-col>
          </v-col>
          <v-col v-else>
            <v-col class="secondary--text body-1 text-center my-1" >
              {{form.email}}
            </v-col>
            <v-col class="my-4 text-center">
              위 메일로 비밀번호를 변경할 수 있는 메일을 전송하였습니다.<br>
              해당 메일에서 비밀번호를 변경하실 수 있습니다.
            </v-col>
            <v-col class="my-4">
              <router-link to="/">
                <v-btn depressed color="primary">홈으로 이동</v-btn>
              </router-link>
            </v-col>
          </v-col>
        </form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'

  export default {
    name: "Passwdch",
    mixins: [userMixin],
    data() {
      return {
        form: {
          email: ''
        },
        step: 0
      }
    },
    methods: {
      onSubmit() {
        let logoutFunc = Promise.resolve();
        if (this.userIsAuthenticated) {
          logoutFunc = this.$store.dispatch('logout')
        }
        this.form.userId = this.$route.query.userId;
        logoutFunc.then(() => this.$store.dispatch('resetPasswordWithEmail', this.form)).then((result) => {
          if (result) {
            this.step = 1
          }
          else {
            this.$store.commit('setMsg', '가입된 이메일이 없습니다.');
            this.$store.commit('setShowSnackbar', true);
          }
        });
      }
    },
    created() {
      this.step = 0;
    }
  }
</script>

<style scoped lang="scss">
  @import '../../assets/scss/form.scss';
  @import '../../assets/scss/custom';

  .line {
    border-bottom: 3px solid #4a4a4a;
  }

  ::v-deep .v-btn {
    margin-left: 0;
    margin-right: 0;
  }
</style>